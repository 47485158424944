import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import YCInput from 'components/YCInput';
import React from 'react';
import Grid from '@mui/material/Grid';
import YCButton from 'components/YCButton';

function FormEdit({
  open,
  handleClose,
  username,
  email,
  name,
  setUsername,
  setEmail,
  setName,
  setOpen,
}) {
  const handleSubmit = () => {
    // Xử lý logic submit form ở đây
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Profile</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <YCInput
              variant='standard'
              label='Username'
              placeholder='Username'
              InputLabelProps={{ shrink: true }}
              fullWidth
              type='text'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <YCInput
              required
              variant='standard'
              label='Email'
              placeholder='Email'
              InputLabelProps={{ shrink: true }}
              fullWidth
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <YCInput
              variant='standard'
              type='text'
              label='Full Name'
              placeholder='Full name'
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <YCButton onClick={handleClose} color='primary'>
          Cancel
        </YCButton>
        <YCButton onClick={handleSubmit} color='primary'>
          Save
        </YCButton>
      </DialogActions>
    </Dialog>
  );
}
export default FormEdit;
