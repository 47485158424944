// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

// Material Kit 2 React components
import YCBox from 'components/YCBox';
import YCTypography from 'components/YCTypography';
const date = new Date().getFullYear();
function Footer({ bgColor }) {
  return (
    <YCBox component='footer' py={6} bgColor={bgColor}>
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={4}
            textAlign={{ xs: 'center', lg: 'left' }}
            mr='auto'
            mb={{ xs: 3, lg: 0 }}
          >
            <YCTypography
              variant='h6'
              textTransform='uppercase'
              mb={{ xs: 2, lg: 3 }}
            >
              Giáo lý
            </YCTypography>
            <Stack
              component='ul'
              direction='row'
              flexWrap='wrap'
              spacing={3}
              justifyContent={{ xs: 'center', lg: 'flex-start' }}
              pl={0}
              mb={3}
              sx={{ listStyle: 'none' }}
            >
              <YCBox component='li'>
                <YCTypography
                  variant='button'
                  fontWeight='regular'
                  opacity={0.8}
                  component={Link}
                  href='/presentation'
                  target='_blank'
                  rel='noreferrer'
                >
                  Home
                </YCTypography>
              </YCBox>
              <YCBox component='li'>
                <YCTypography
                  variant='button'
                  fontWeight='regular'
                  opacity={0.8}
                  component={Link}
                  href='https://cmate.vn/tong-do-it.html'
                  target='_blank'
                  rel='noreferrer'
                >
                  About
                </YCTypography>
              </YCBox>
              <YCBox component='li'>
                <YCTypography
                  variant='button'
                  fontWeight='regular'
                  opacity={0.8}
                  component={Link}
                  href='#'
                  target='_blank'
                  rel='noreferrer'
                >
                  Blog
                </YCTypography>
              </YCBox>
            </Stack>
            <YCTypography variant='button' opacity={0.8}>
              Copyright ©<span>{' ' + date + ' '}</span>
              <YCTypography variant='button' opacity={0.8}>
                made with by
              </YCTypography>{' '}
              <YCTypography variant='button' fontWeight='medium'>
                Nomads - Tông Đồ IT
              </YCTypography>{' '}
              for a better web.
            </YCTypography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            ml='auto'
            textAlign={{ xs: 'center', lg: 'right' }}
          >
            <YCTypography
              variant='body1'
              fontWeight='bold'
              mb={6}
              sx={{ fontSize: '1.125rem' }}
            >
              Hãy tìm Chúa, bạn sẽ tìm thấy ý nghĩa cuộc đời.
              <YCTypography
                variant='body1'
                fontWeight='bold'
                mb={6}
                sx={{ fontSize: '1.125rem' }}
              >
                (Carlo Acutis)
              </YCTypography>
            </YCTypography>
          </Grid>
        </Grid>
      </Container>
    </YCBox>
  );
}

export default Footer;
