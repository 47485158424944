// Material Kit 2 React components
import YCBox from 'components/YCBox';

// Material Kit 2 React examples
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';

// Routes
import routes from 'routes';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';

// React hooks
import { useState } from 'react';

// Material Kit 2 React components
import YCInput from 'components/YCInput';
import YCButton from 'components/YCButton';
import YCTypography from 'components/YCTypography';
import axios from 'axios';

// Images
import bgImageForm from 'assets/images/bg-form-sign-up.jpeg';

function SignUp() {
  const [showPassword, setShowPassword] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = { username, password, email, name };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/sign-up`,
        payload
      );
      navigate('/authentication/sign-in');
    } catch (error) {
      setError(error?.response?.data?.message);
    }
  };
  return (
    <>
      <DefaultNavbar routes={routes} transparent black />
      <YCBox bgColor='yellow'>
        <YCBox minHeight='5rem' width='100%' bgColor='' />
        <YCBox component='section' py={{ xs: 0, lg: 6 }}>
          <Container>
            <Grid container item>
              <YCBox
                width='100%'
                bgColor='white'
                borderRadius='xl'
                // shadow='xl'
                mb={6}
                sx={{
                  overflow: 'hidden',
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    lg={5}
                    position='relative'
                    px={0}
                    sx={{
                      backgroundImage: ({
                        palette: { gradients },
                        functions: { rgba, linearGradient },
                      }) =>
                        `${linearGradient(
                          rgba(gradients.dark.main, 0),
                          rgba(gradients.dark.state, 0)
                        )}, url(${bgImageForm})`,
                      backgroundSize: 'cover',
                    }}
                  ></Grid>
                  <Grid item xs={12} lg={7}>
                    <YCBox
                      component='form'
                      p={2}
                      role='form'
                      onSubmit={handleSubmit}
                    >
                      <YCBox px={3} py={{ xs: 2, sm: 6 }}>
                        <YCTypography variant='h2' mb={1}>
                          Say Hi!
                        </YCTypography>

                        {error && (
                          <YCTypography
                            variant='caption'
                            color='error'
                            display='block'
                          >
                            {error}
                          </YCTypography>
                        )}
                      </YCBox>
                      <YCBox pt={0.5} pb={3} px={3}>
                        <Grid container>
                          <Grid item xs={12} pr={1} mb={6}>
                            <YCInput
                              variant='standard'
                              label='Username'
                              placeholder='Username'
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              type='text'
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} pr={1} mb={6}>
                            <YCInput
                              required
                              variant='standard'
                              label='Email'
                              placeholder='Email'
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              type='email'
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} pr={1} mb={6}>
                            <YCInput
                              variant='standard'
                              type='text'
                              label='Full Name'
                              placeholder='Full name'
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} pr={1} mb={6}>
                            <YCInput
                              required
                              variant='standard'
                              label='Password'
                              placeholder='Password'
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              type={showPassword ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      onClick={handleClickShowPassword}
                                      edge='end'
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          md={6}
                          justifyContent='flex-end'
                          textAlign='right'
                          ml='auto'
                        >
                          <YCButton
                            variant='gradient'
                            color='info'
                            type='submit'
                          >
                            Sign Up
                          </YCButton>
                        </Grid>
                      </YCBox>
                    </YCBox>
                  </Grid>
                </Grid>
              </YCBox>
            </Grid>
          </Container>
        </YCBox>
        {/* <Footer /> */}
      </YCBox>
    </>
  );
}

export default SignUp;
