// @mui material components
// import Icon from '@mui/material/Icon';
import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Pages
// import AboutUs from 'layouts/pages/landing-pages/about-us';
// import ContactUs from 'layouts/pages/landing-pages/contact-us';
// import Author from 'layouts/pages/landing-pages/author';

import ExamsPages from 'layouts/pages/exams/CreateExam';
import ProfilePage from 'layouts/pages/authentication/profile';
import LogOut from 'pages/LandingPages/Profile/logout';
const routes = [
  // {
  //   name: 'pages',
  //   icon: <Icon>dashboard</Icon>,
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   collapse: [
  //     {
  //       name: 'landing pages',
  //       collapse: [
  //         {
  //           name: 'about us',
  //           route: '/pages/landing-pages/about-us',
  //           component: <AboutUs />,
  //         },
  //         {
  //           name: 'contact us',
  //           route: '/pages/landing-pages/contact-us',
  //           component: <ContactUs />,
  //         },
  //         {
  //           name: 'author',
  //           route: '/pages/landing-pages/author',
  //           component: <Author />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    route: '/page/exams/CreateExam',
    name: 'Luyện Thi',
    component: <ExamsPages />,
    columns: 1,
    icon: (
      <SelfImprovementOutlinedIcon>
        SelfImprovementOutlinedIcon
      </SelfImprovementOutlinedIcon>
    ),
  },
  {
    name: 'account',
    rowsPerColumn: 2,
    columns: 1,
    icon: <AccountCircleIcon>AccountCircleIcon</AccountCircleIcon>,
    collapse: [
      {
        name: 'Profile',
        collapse: [
          {
            name: 'my profile',
            route: '/pages/authentication/profile',
            component: <ProfilePage />,
          },
          {
            name: 'log out',
            route: '/pages/authentication/profile/logout',
            component: <LogOut />,
          },
        ],
      },
    ],
  },
];

export default routes;
