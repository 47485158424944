/* eslint-disable react/prop-types */
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

function ExamConfirmationPopup({ showPopup, onClose, onConfirm }) {
  return (
    <Dialog
      open={showPopup}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Xác nhận nộp bài</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Bạn có chắc chắn muốn rời khỏi trang này? Nếu có, bài thi của bạn sẽ được nộp ngay lập
          tức.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Hủy
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExamConfirmationPopup;
