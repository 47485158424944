// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Youcat React components
import YCBox from 'components/YCBox';

// Youcat React examples
import DefaultCounterCard from 'examples/Cards/CounterCards';

function Counters({ countUser, countQuestion, countBook }) {
  return (
    <YCBox component='section' py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: 'auto' }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={countUser}
              suffix='+'
              title='Số người dùng'
              description='Số người đã tham gia luyện tập, đọc sách, học hỏi.'
            />
          </Grid>
          <Grid item xs={12} md={4} display='flex'>
            <Divider
              orientation='vertical'
              sx={{ display: { xs: 'none', md: 'block' }, mx: 0 }}
            />
            <DefaultCounterCard
              count={countQuestion}
              suffix='+'
              title='Số câu hỏi'
              description='Số lượng câu hỏi có thể trải nghiệm.'
            />
            <Divider
              orientation='vertical'
              sx={{ display: { xs: 'none', md: 'block' }, ml: 0 }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={countBook}
              suffix='+'
              title='Cuốn'
              description='Số lượng sách'
            />
          </Grid>
        </Grid>
      </Container>
    </YCBox>
  );
}

export default Counters;
