/* eslint-disable react/prop-types */
import React from 'react';
import YCBox from 'components/YCBox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function ExamQuestion({
  question,
  selectedAnswer,
  handleAnswerSelect,
  totalCells,
  selectedCell,
}) {
  return (
    <>
      <Typography variant='h4'>
        Câu hỏi {selectedCell + 1}/{totalCells}:
      </Typography>

      <Typography variant='h5'>{question?.name}</Typography>
      <YCBox mt={2}>
        {question?.Answers?.map((answer, index) => (
          <Button
            key={index}
            variant='contained'
            fullWidth
            sx={{
              mt: 1,
              backgroundColor:
                selectedAnswer?.answer_id === answer?.id
                  ? 'yellow'
                  : 'white.main',
              textTransform: 'none',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            onClick={() => handleAnswerSelect(answer?.id, question?.id)}
          >
            <Box sx={{ mr: 1 }} variant='h5'>
              <Typography variant='h5'>{answer?.code_option}.</Typography>
            </Box>
            {answer?.name}
          </Button>
        ))}
      </YCBox>
    </>
  );
}

export default ExamQuestion;
