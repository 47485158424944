import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

function LogOut() {
  const [, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    setIsAuthenticated(false);
    navigate('/pages/authentication/sign-in');
  }, [navigate]);
}

export default LogOut;
