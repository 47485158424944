// @mui material components
import Card from '@mui/material/Card';

// YC React components
import YCBox from 'components/YCBox';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import YCButton from 'components/YCButton';

// Images
import bgImage from 'assets/images/footer-bg.jpeg';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ExamGridCells from 'components/YCExams/ExamGridCell';
import ExamTimer from 'components/YCExams/ExamTimer';
import ExamQuestion from 'components/YCExams/ExamQuestion';
import ExamConfirmationPopup from 'components/YCExams/ExamConfirmationPopup';

function ExamBasic() {
  const [totalPages, setTotalPages] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCell, setSelectedCell] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(totalPages).fill(null)
  );
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const handleSubmit = async () => {
    setIsSubmitted(true);
    const completeAnswers = questions.map((question, index) => {
      const selectedAnswer = selectedAnswers[index];
      return {
        question_id: question.id,
        answer_id: selectedAnswer ? selectedAnswer.answer_id : null,
      };
    });

    const payloadData = {
      execution_time: data.due_time - timeLeft,
      answers: completeAnswers,
    };

    try {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + accessToken;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/exam/submit/` +
          data?.exam_id,
        payloadData
      );
      const resultData = response.data;
      if (resultData) {
        localStorage.removeItem('examData');

        localStorage.setItem('resultData', JSON.stringify(resultData));
        navigate('/results');
      }
    } catch (error) {
      console.error('Error submitting answers:', error);
      // setError(error);
    }
  };
  const handleFormSubmit = (event) => {
    event.preventDefault(); // Ngăn chặn hành động mặc định của form
    setShowPopup(true); // Hiển thị popup xác nhận
  };

  const handleConfirmReload = () => {
    handleSubmit();
  };

  const handleCancelReload = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      setShowPopup(true);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    let data;
    if (location.state && location.state.data) {
      data = location.state.data;
    } else {
      const storedData = localStorage.getItem('examData');
      if (storedData) data = JSON.parse(storedData);
    }
    if (!data) navigate('/');
    setData(data);
    setQuestions(data?.questions);
    setTotalPages(data?.questions.length);

    // Reset selectedAnswers when totalPages changes
    setSelectedAnswers(Array(data?.questions.length).fill(null));
    setTimeLeft(data.due_time);
  }, [location.state]);

  useEffect(() => {
    if (timeLeft <= 0) {
      handleSubmit();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  });

  const handleAnswerSelect = (questionIndex, answerId) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[questionIndex] = {
      question_id: questions[questionIndex].id,
      answer_id: answerId,
    };
    setSelectedAnswers(updatedAnswers);
  };

  const handleCellSelect = (index) => {
    setSelectedCell(index);
    setCurrentPage(index + 1);
  };

  const handleNavigatePage = (direction) => {
    const newPage = currentPage + direction;
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setSelectedCell(newPage - 1); // cập nhật selectedCell tương ứng với currentPage
    }
  };

  return (
    <>
      {/* <DefaultNavbar routes={routes} transparent light /> */}
      <YCBox bgColor='yellow'>
        <YCBox
          minHeight='5rem'
          width='100%'
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'grid',
            placeItems: 'center',
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: 'saturate(200%) blur(30px)',
            boxShadow: ({ boxShadows: { xxl } }) => xxl,

            border: timeLeft <= 300 ? '2px solid red' : 'none',
          }}
        >
          <YCBox component='section' py={{ xs: 6, sm: 12 }}>
            <Container backgroundColor='yellow'>
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                mx='auto'
                component='form'
                role='form'
                onSubmit={handleFormSubmit}
              >
                <Grid xs={12} sm={5} md={4} lg={4} xl={4}>
                  <ExamTimer timeLeft={timeLeft} />
                </Grid>
                <Grid container justifyContent='center' py={6}>
                  <Grid
                    xs={12}
                    sm={5}
                    md={8}
                    lg={8}
                    xl={8}
                    sx={{ minHeight: '500px' }}
                  >
                    <YCBox pt={2} pb={2} px={2}>
                      {selectedCell !== null && questions[selectedCell] ? (
                        <ExamQuestion
                          question={questions[selectedCell]}
                          selectedAnswer={selectedAnswers[selectedCell]}
                          handleAnswerSelect={(answerId) =>
                            handleAnswerSelect(selectedCell, answerId)
                          }
                          totalCells={totalPages}
                          selectedCell={selectedCell}
                        />
                      ) : (
                        <Typography variant='h5'>
                          Vui lòng chọn một ô
                        </Typography>
                      )}
                      <YCBox mt={4} mb={1} textAlign='center'>
                        <YCButton
                          variant='outlined'
                          color='info'
                          onClick={() => handleNavigatePage(-1)}
                          disabled={currentPage === 1}
                          sx={{ mx: 1 }}
                        >
                          Quay lại
                        </YCButton>
                        <YCButton
                          variant='outlined'
                          color='info'
                          onClick={() => handleNavigatePage(1)}
                          disabled={currentPage === totalPages}
                          sx={{ mx: 1 }}
                        >
                          Tiếp theo
                        </YCButton>
                      </YCBox>
                    </YCBox>
                  </Grid>
                  <Grid item xs={12} sm={5} md={4} lg={4} xl={4}>
                    <Card sx={{ padding: 2 }}>
                      <Typography variant='h6' align='center' marginBottom={3}>
                        Bảng Các Ô
                      </Typography>
                      <ExamGridCells
                        totalCells={totalPages}
                        selectedCell={selectedCell}
                        selectedAnswers={selectedAnswers}
                        handlePageChange={(_, value) =>
                          handleCellSelect(value - 1)
                        }
                      />
                      <YCBox sx={{ padding: 7 }}>
                        <YCButton
                          variant='gradient'
                          color='success'
                          fullWidth
                          type='submit'
                          onClick={() => setShowPopup(true)}
                          disabled={isSubmitted}
                        >
                          Nộp Bài
                        </YCButton>
                      </YCBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </YCBox>
        </Card>

        <ExamConfirmationPopup
          showPopup={showPopup}
          onClose={handleCancelReload}
          onConfirm={handleConfirmReload}
        />
        {/* <Footer /> */}
      </YCBox>
    </>
  );
}

export default ExamBasic;
