import { useState } from 'react';

// react-router components
import { Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Collapse from '@mui/material/Collapse';
import MuiLink from '@mui/material/Link';

// Material Kit 2 React components
import YCBox from 'components/YCBox';
import YCTypography from 'components/YCTypography';

// Material Kit 2 React example components
import DefaultNavbarDropdown from 'examples/Navbars/DefaultNavbar/DefaultNavbarDropdown';

function DefaultNavbarMobile({ routes, open, isAuthenticated = false }) {
  const [collapse, setCollapse] = useState('');

  const handleSetCollapse = (name) =>
    collapse === name ? setCollapse(false) : setCollapse(name);

  const renderNavbarItems = routes.map(
    ({
      name,
      icon,
      collapse: routeCollapses,
      href,
      route,
      collapse: navCollapse,
    }) =>
      !isAuthenticated && name === 'account' ? null : (
        <DefaultNavbarDropdown
          key={name}
          name={name}
          icon={icon}
          collapseStatus={name === collapse}
          onClick={() => handleSetCollapse(name)}
          href={href}
          route={route}
          collapse={Boolean(navCollapse)}
        >
          <YCBox
            sx={{ height: '15rem', maxHeight: '15rem', overflowY: 'scroll' }}
          >
            {routeCollapses &&
              routeCollapses.map((item) => (
                <YCBox key={item.name} px={2}>
                  {item.collapse ? (
                    <>
                      <YCTypography
                        display='block'
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                        py={1}
                        px={0.5}
                      >
                        {item.name}
                      </YCTypography>
                      {item.collapse.map((el) => (
                        <YCTypography
                          key={el.name}
                          component={el.route ? Link : MuiLink}
                          to={el.route ? el.route : ''}
                          href={el.href ? el.href : ''}
                          target={el.href ? '_blank' : ''}
                          rel={el.href ? 'noreferrer' : 'noreferrer'}
                          minWidth='11.25rem'
                          display='block'
                          variant='button'
                          color='text'
                          textTransform='capitalize'
                          fontWeight='regular'
                          py={0.625}
                          px={2}
                          sx={({
                            palette: { grey, dark },
                            borders: { borderRadius },
                          }) => ({
                            borderRadius: borderRadius.md,
                            cursor: 'pointer',
                            transition: 'all 300ms linear',

                            '&:hover': {
                              backgroundColor: grey[200],
                              color: dark.main,
                            },
                          })}
                        >
                          {el.name}
                        </YCTypography>
                      ))}
                    </>
                  ) : (
                    <YCBox
                      key={item.key}
                      display='block'
                      component={item.route ? Link : MuiLink}
                      to={item.route ? item.route : ''}
                      href={item.href ? item.href : ''}
                      target={item.href ? '_blank' : ''}
                      rel={item.href ? 'noreferrer' : 'noreferrer'}
                      sx={({
                        palette: { grey, dark },
                        borders: { borderRadius },
                      }) => ({
                        borderRadius: borderRadius.md,
                        cursor: 'pointer',
                        transition: 'all 300ms linear',
                        py: 1,
                        px: 1.625,

                        '&:hover': {
                          backgroundColor: grey[200],
                          color: dark.main,

                          '& *': {
                            color: dark.main,
                          },
                        },
                      })}
                    >
                      <YCTypography
                        display='block'
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        {item.name}
                      </YCTypography>
                      <YCTypography
                        display='block'
                        variant='button'
                        color='text'
                        fontWeight='regular'
                        sx={{ transition: 'all 300ms linear' }}
                      >
                        {item.description}
                      </YCTypography>
                    </YCBox>
                  )}
                </YCBox>
              ))}
          </YCBox>
        </DefaultNavbarDropdown>
      )
  );

  return (
    <Collapse in={Boolean(open)} timeout='auto' unmountOnExit>
      <YCBox width='calc(100% + 1.625rem)' my={2} ml={-2}>
        {renderNavbarItems}
      </YCBox>
    </Collapse>
  );
}

// Typechecking props for the DefaultNavbarMobile
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
