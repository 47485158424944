import YCTypography from 'components/YCTypography';

// Images
import logoCT from 'assets/images/footer-bg.jpeg';

const date = new Date().getFullYear();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  brand: {
    name: 'YouCat',
    image: logoCT,
    route: '/',
  },
  socials: [],
  menus: [],
  copyright: (
    <YCTypography variant='button' fontWeight='regular'>
      All rights reserved. Copyright &copy; {date} GiaoLy by{' '}
      <YCTypography
        component='a'
        target='_blank'
        rel='noreferrer'
        variant='button'
        fontWeight='medium'
      >
        Nomads - Tong Đo IT
      </YCTypography>
      .
    </YCTypography>
  ),
};
