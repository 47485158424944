// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Kit 2 React components
import YCBox from 'components/YCBox';
import YCTypography from 'components/YCTypography';

function DefaultInfoCard({
  color,
  icon,
  title,
  description,
  direction,
  small,
}) {
  return (
    <YCBox
      lineHeight={1}
      p={direction === 'center' ? 2 : 0}
      textAlign={direction}
    >
      <YCBox display='flex' alignItems='center'>
        {typeof icon === 'string' ? (
          <YCTypography
            display='block'
            variant={direction === 'center' ? 'h2' : 'h3'}
            color={color}
            textGradient
          >
            {' '}
            <Icon>{icon}</Icon>{' '}
          </YCTypography>
        ) : (
          icon
        )}
        <YCTypography
          display='block'
          variant='5'
          fontWeight='bold'
          // mt={direction === 'center' ? 1 : 2}
          mb={1}
        >
          {title}
        </YCTypography>
      </YCBox>

      <YCTypography
        display='block'
        variant={small ? 'button' : 'body2'}
        color='text'
        pr={direction === 'left' ? 6 : 0}
        pl={direction === 'right' ? 6 : 0}
      >
        {Array.isArray(description)
          ? description.map((item, index) => <div key={index}>{item}</div>)
          : description}
      </YCTypography>
    </YCBox>
  );
}

// Setting default props for the DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color: 'info',
  direction: 'left',
  small: false,
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['left', 'right', 'center']),
  small: PropTypes.bool,
};

export default DefaultInfoCard;
