import React, { useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import YCBox from 'components/YCBox';
import YCTypography from 'components/YCTypography';
import YCButton from 'components/YCButton';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import SimpleFooter from 'examples/Footers/SimpleFooter';
import routes from 'routes';
import bgImage from 'assets/images/YcQ.jpg';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import axios from 'axios'; // Sử dụng axios để gọi API
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from 'components/Auth/setUpAxios';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';

function ExamsCreate() {
  const [value, setValue] = useState('15');
  const [error, setError] = useState(null);
  const [quantityQuestion, setQuantityQuestion] = useState(0);
  const [customQuantity, setCustomQuantity] = useState('');
  const [book_id, setBook] = useState(1);
  const navigate = useNavigate();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeSelect = (event) => {
    setQuantityQuestion(event.target.value);
  };

  const handleCustomQuantityChange = (event) => {
    setCustomQuantity(event.target.value);
  };
  const handleChangeSelectBook = (event) => {
    setBook(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const finalQuantity =
      quantityQuestion === 'other' ? Number(customQuantity) : quantityQuestion;

    const payload = {
      due_time: Number(value),
      quantity_question: finalQuantity ? Number(finalQuantity) : Number(value),
      book_id: book_id,
    };

    try {
      const accessToken = localStorage.getItem('accessToken');
      let endPoint = 'api/exam/create-quickly';
      if (accessToken) endPoint = 'api/exam/create';
      setAuthToken();
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${endPoint}`,
        payload
      );
      const data = response.data;
      localStorage.setItem('examData', JSON.stringify(data));
      navigate('/exams', { state: { data } });
    } catch (error) {
      console.error('Lỗi khi gọi API:', error);
      setError('Đã có lỗi xảy ra. Vui lòng thử lại.');
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} />
      <YCBox
        position='absolute'
        top={0}
        left={0}
        zIndex={1}
        width='100%'
        minHeight='100vh'
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <YCBox
        px={1}
        width='100%'
        minHeight='100vh'
        mx='auto'
        position='relative'
        zIndex={2}
        paddingTop={15}
      >
        <Grid
          container
          spacing={1}
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <Grid item xs={12} sm={9} md={6} lg={5} xl={4}>
            <Card sx={{ width: '100%' }}>
              <YCBox
                variant='gradient'
                bgColor='info'
                borderRadius='lg'
                coloredShadow='info'
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign='center'
              >
                <YCTypography
                  variant='h4'
                  fontWeight='medium'
                  color='white'
                  mt={1}
                >
                  Tạo bài thi
                </YCTypography>
              </YCBox>
              <YCBox pt={4} pb={3} px={3}>
                <YCBox component='form' role='form' onSubmit={handleSubmit}>
                  <YCBox mb={2}>
                    <FormControl sx={{ ml: 2, minWidth: '50%' }}>
                      <h4>Chọn sách</h4>
                      <Select
                        labelId='quantity-select-label'
                        id='quantity-select'
                        value={book_id}
                        label='Chọn số câu hỏi'
                        onChange={handleChangeSelectBook}
                        multiline
                        sx={{
                          fontSize: '1rem',
                          minHeight: '50px',
                        }}
                        required
                      >
                        <MenuItem
                          value={1}
                          sx={{ fontSize: '1rem' }}
                          defaultValue={1}
                        >
                          Youcat
                        </MenuItem>
                        <MenuItem value={2} sx={{ fontSize: '1rem' }}>
                          Toát Yếu Giáo Lý
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={value}
                      onChange={handleChange}
                      sx={{ ml: 2 }}
                    >
                      <h4>Chọn thời gian</h4>

                      <FormControlLabel
                        value='15'
                        control={<Radio />}
                        label='Thời lượng 15 phút'
                      />
                      <FormControlLabel
                        value='30'
                        control={<Radio />}
                        label='Thời lượng 30 phút'
                      />
                      <FormControlLabel
                        value='45'
                        control={<Radio />}
                        label='Thời lượng 45 phút'
                      />
                      <FormControlLabel
                        value='120'
                        control={<Radio />}
                        label='Thời lượng 120 phút'
                      />
                    </RadioGroup>

                    <FormControl sx={{ ml: 2, minWidth: '50%', paddingTop: 2 }}>
                      <h4>Chọn số câu hỏi</h4>

                      <Select
                        labelId='quantity-select-label'
                        id='quantity-select'
                        value={quantityQuestion}
                        label='Chọn số câu hỏi'
                        onChange={handleChangeSelect}
                        multiline
                        sx={{
                          fontSize: '1rem',
                          height: '60px',
                        }}
                        required
                      >
                        <MenuItem value={0} sx={{ fontSize: '1rem' }}>
                          Mặc định
                        </MenuItem>
                        <MenuItem value={5} sx={{ fontSize: '1rem' }}>
                          5
                        </MenuItem>
                        <MenuItem value={10} sx={{ fontSize: '1em' }}>
                          10
                        </MenuItem>
                        <MenuItem value='other' sx={{ fontSize: '1rem' }}>
                          Khác
                        </MenuItem>
                      </Select>

                      {quantityQuestion === 'other' && (
                        <TextField
                          sx={{ mt: 2, fontSize: '1.2rem', height: '50px' }} // Tăng kích thước input
                          label='Nhập số câu hỏi'
                          type='number'
                          value={customQuantity}
                          onChange={handleCustomQuantityChange}
                          fullWidth
                        />
                      )}
                    </FormControl>
                  </YCBox>

                  <YCBox pb={3} px={3} mt={4} mb={1}>
                    <YCButton
                      variant='gradient'
                      color='info'
                      fullWidth
                      type='submit'
                    >
                      Tạo bài
                    </YCButton>
                  </YCBox>
                </YCBox>
              </YCBox>
            </Card>
          </Grid>
        </Grid>
      </YCBox>

      <YCBox
        width='100%'
        position='bottom'
        zIndex={3}
        bottom='1.625rem'
        paddingTop={2}
      >
        <SimpleFooter dark />
      </YCBox>
      {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
    </>
  );
}

export default ExamsCreate;
