import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Tạo context
const AuthContext = createContext();

// Tạo provider cho context
export function AuthProvider({ children }) {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken') || ''
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem('refreshToken') || ''
  );
  const navigate = useNavigate();

  useEffect(() => {
    // Kiểm tra và làm mới token nếu cần khi component mount
    const checkAndRefreshToken = async () => {
      if (!accessToken || !refreshToken) return;

      try {
        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000; // Current time in seconds

        if (decodedToken.exp < currentTime) {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/auth/refresh-token`,
            {
              refreshToken: refreshToken,
            }
          );

          if (response.status === 200) {
            const data = await response.data;
            setAccessToken(data.accessToken);
            localStorage.setItem('accessToken', JSON.parse(accessToken));

            axios.interceptors.request.use((config) => {
              config.headers.Authorization = `Bearer ${accessToken}`;
            });
          } else {
            console.error('Failed to refresh token');
          }
        }
      } catch (error) {
        localStorage.removeItem('accessToken');
        window.location.reload();
        // navigate('/authentication/sign-in');
        console.error('Error checking or refreshing token:', error);
      }
    };

    checkAndRefreshToken();
  }, [accessToken, navigate, refreshToken]);

  const value = {
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// Custom hook để sử dụng context
export function useAuth() {
  return useContext(AuthContext);
}
