import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import YCBox from 'components/YCBox';
import YCButton from 'components/YCButton';
import ExamGridCellResult from 'components/YCExams/ExamGridCellResult';
import Footer from 'pages/LandingPages/Footer';
import routes from 'routes';
import ExamQuestionResult from 'components/YCExams/ExamQuestionResult';
import bgImage from 'assets/images/you-cat-book.jpeg';

function ExamResults() {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCell, setSelectedCell] = useState(0);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const storedData = localStorage.getItem('resultData');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        setData(parsedData);
      }
    };

    if (!data) fetchData();
  }, []);

  const questions = useMemo(() => data?.Questions || [], [data]);
  const totalPages = useMemo(() => questions.length, [questions]);
  const selectedAnswers = useMemo(
    () => Array(totalPages).fill(null),
    [totalPages]
  );

  const handleCellSelect = useCallback((index) => {
    setSelectedCell(index);
    setCurrentPage(index + 1);
  }, []);

  const handleNavigatePage = useCallback(
    (direction) => {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + direction;
        if (newPage > 0 && newPage <= totalPages) {
          setSelectedCell(newPage - 1);
          return newPage;
        }
        return prevPage;
      });
    },
    [totalPages]
  );

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <YCBox bgColor='#E0FFFF'>
        <YCBox
          minHeight='10rem'
          width='100%'
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'grid',
            placeItems: 'center',
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: 'saturate(200%) blur(30px)',
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <YCBox component='section' py={{ xs: 4, sm: 10 }}>
            <Container>
              <Grid
                container
                item
                xs={12}
                justifyContent='center'
                mx='auto'
                component='form'
                role='form'
              >
                <Grid xs={12} sm={5} md={4} lg={4} xl={4}>
                  <YCBox mt={-5} mb={-5} textAlign='center'>
                    <h2>
                      Thời gian hoàn thành: {formatTime(data?.execution_time)}/
                      {formatTime(data?.due_time)}
                    </h2>
                  </YCBox>
                </Grid>
                <Grid container justifyContent='center' py={3}>
                  <Grid xs={12} sm={5} md={8} lg={8} xl={8}>
                    <YCBox pt={2} pb={2} px={2}>
                      {selectedCell !== null && questions[selectedCell] ? (
                        <ExamQuestionResult
                          question={questions[selectedCell]}
                          totalCells={totalPages}
                          selectedCell={selectedCell}
                        />
                      ) : (
                        <Typography variant='h5'>
                          Vui lòng chọn một ô
                        </Typography>
                      )}
                      <YCBox mt={4} mb={1} textAlign='center'>
                        <YCButton
                          variant='outlined'
                          color='info'
                          onClick={() => handleNavigatePage(-1)}
                          disabled={currentPage === 1}
                          sx={{ mx: 1 }}
                        >
                          Quay lại
                        </YCButton>
                        <YCButton
                          variant='outlined'
                          color='info'
                          onClick={() => handleNavigatePage(1)}
                          disabled={currentPage === totalPages}
                          sx={{ mx: 1 }}
                        >
                          Tiếp theo
                        </YCButton>
                      </YCBox>
                    </YCBox>
                  </Grid>
                  <Grid item xs={12} sm={5} md={4} lg={4} xl={4}>
                    <Card sx={{ padding: 2 }}>
                      <Typography variant='h6' align='center' marginBottom={3}>
                        Bảng Các Ô
                      </Typography>
                      <ExamGridCellResult
                        totalCells={totalPages}
                        selectedCell={selectedCell}
                        selectedAnswers={selectedAnswers}
                        handlePageChange={(_, value) =>
                          handleCellSelect(value - 1)
                        }
                        questions={questions}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </YCBox>
        </Card>

        <Footer />
      </YCBox>
    </>
  );
}

export default ExamResults;
