// Material Kit 2 React helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

export default {
  styleOverrides: {
    root: {
      padding: `0 ${pxToRem(6)}`,
    },
  },
};
