/* eslint-disable react/prop-types */
import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

function ExamGridCells({ totalCells, selectedCell, selectedAnswers, handlePageChange }) {
  const createGridCells = () => {
    const gridCells = [];
    for (let i = 0; i < totalCells; i++) {
      gridCells.push(
        <Paper
          key={i}
          elevation={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 50,
            width: 50,
            margin: 0.5,
            cursor: "pointer",
            backgroundColor:
              selectedAnswers[i] !== null ? "yellow" : selectedCell === i ? "yellow" : "white",
            color: selectedAnswers[i] !== null ? "white" : "black",
          }}
          onClick={() => handlePageChange(null, i + 1)}
        >
          {i + 1}
        </Paper>
      );
    }
    return gridCells;
  };

  return (
    <Grid container justifyContent="center">
      {createGridCells()}
    </Grid>
  );
}

export default ExamGridCells;
