/* eslint-disable react/prop-types */
import React from 'react';
import YCBox from 'components/YCBox';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

function ExamQuestionResult({ question, totalCells, selectedCell }) {
  const handleCellSelect = (answer) => {
    if (answer?.result) return '#6fbf73';
    if (
      answer.answer_choose_id == null &&
      answer.answer_id === question.answer_id
    )
      return 'yellow';
    if (answer.answer_choose_id != null) {
      if (answer.answer_choose_id === answer.answer_id) return '#ff784e';
      if (answer.answer_id === question.answer_id) return '#6fbf73';
    }
  };

  return (
    <>
      <Typography variant='h4'>
        Câu hỏi {selectedCell + 1}/{totalCells}:
      </Typography>
      <Typography variant='h5'>{question?.name}</Typography>
      <YCBox mt={2}>
        {question?.Answers?.map((answer, index) => (
          <Button
            key={index}
            variant='contained'
            fullWidth
            disableElevation
            sx={{
              mt: 1,
              backgroundColor: handleCellSelect(answer) || 'white.main',
              textTransform: 'none',
              display: 'flex',
              justifyContent: 'flex-start',
              contrastText: '#ffffff',
            }}
          >
            <Box sx={{ mr: 1 }}>
              <Typography variant='h5'>{answer?.code_option}.</Typography>
            </Box>
            {answer?.name_answer}
          </Button>
        ))}
      </YCBox>
    </>
  );
}

export default ExamQuestionResult;
